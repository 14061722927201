import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

export default class Guestbook extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            city: '',
            message: '',
            errorMessage: '',
            fetchError: '',
            entries: [],
        };
    }

    componentDidMount() {
        fetch(`/.netlify/functions/getGuestbookEntries`)
            .then((r) => r.json())
            .then((entries) => {
                this.setState({
                    entries
                });
            })
            .catch((error) => {
                this.setState({
                    fetchError: error.message,
                });
            });
    }

    handleChange = (event) => {
        this.setState({
            errorMessage: ''
        });

        const target = event.target;
        const { name, value } = target;

        this.setState({
            [name]: value,
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();

        if (this.checkForExistingEmail()) {
            this.setState({
                errorMessage: 'That email has already signed the guestbook'
            });
            return;
        }

        this.submitDataToServer();
    };

    submitDataToServer() {
        const newSubmission = {
            name: this.state.name,
            email: this.state.email,
            message: this.state.message,
            city: this.state.city
        };

        fetch(`/.netlify/functions/submitGuestbookEntry`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(newSubmission)
        })
        .then((r) => r.json())
        .then((data) => {
            if (data.hasOwnProperty('error')) {
                this.setState({
                    submitError: data.error,
                });

                return;
            }

            this.setState({
                entries: this.state.entries.concat(newSubmission)
            }, this.clearFormFields);

        })
        .catch((error) => {
            this.setState({
                submitError: error.message,
            });
        });
    }

    clearFormFields() {
        this.setState({
            name: '',
            email: '',
            city: '',
            message: '',
        });
    }

    checkForExistingEmail() {
        return this.state.entries.some((entry) => {
            return entry.email === this.state.email;
        });
    }

    enableButtonState() {
        return !this.state.name || !this.state.email || !this.state.message || !this.state.city;
    }

    render() {
        const entries = this.state.entries.length > 0 ? this.state.entries : [];
        const errorMessage = this.state.errorMessage || null;
        const fetchError = this.state.fetchError || null;
        const disabledButtonState = this.enableButtonState();

        return (
            <Layout>
                <SEO title="Guestbook" />

                <div className="content">
                    <h1 style={{color: 'black', fontSize: '50px'}}>Guestbook</h1>

                    <p style={{color: 'black'}}>{fetchError}</p>

                    <table style={{ border: '1px inset black', margin: '40px auto'}}>
                        <thead>
                        <tr style={{ border: '1px inset black'}}>
                            <td style={{ border: '1px inset black', color: 'black'}}>Name</td>
                            <td style={{ border: '1px inset black', color: 'black'}}>City</td>
                            <td style={{ border: '1px inset black', color: 'black'}}>Message</td>
                        </tr>
                        </thead>
                        <tbody style={{color: 'black'}}>
                        {entries.map((entry, i) => {
                            return(
                                <tr style={{ border: '1px inset black'}} key={i}>
                                    <td style={{ border: '1px inset black', color: 'black'}}>{entry.name}</td>
                                    <td style={{ border: '1px inset black', color: 'black'}}>{entry.city}</td>
                                    <td style={{ border: '1px inset black', color: 'black'}}>{entry.message}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>

                    <h2 style={{color: 'black', fontSize: '50px'}}>Sign the Guestbook</h2>

                    <form onSubmit={this.handleSubmit}>
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" required="required" value={this.state.name} onChange={this.handleChange}/>

                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" required="required" value={this.state.email} onChange={this.handleChange}/>

                        <label htmlFor="city">City</label>
                        <input type="text" name="city" required="required" value={this.state.city} onChange={this.handleChange}/>

                        <label htmlFor="message">Message</label>
                        <textarea name="message" required="required" value={this.state.message} onChange={this.handleChange} />

                        <p>{errorMessage}</p>

                        <button type="submit" disabled={disabledButtonState}>Submit</button>
                    </form>
                </div>
            </Layout>
        );
    }
}
